import React, { useCallback, useEffect, useRef, useState } from 'react'

import Image from 'src/components/image/index'

import $ from 'jquery'

import styles from './index.module.scss'

const Slider = ({ items }) => {
	const scroller = useRef()
	const wrapper = useRef()
	const [prevHidden, setPrevHidden] = useState(true)
	const [nextHidden, setNextHidden] = useState(false)

	useEffect(() => {
		function resize() {
			const width = document.body.clientWidth

			if (window.innerWidth >= 880) {
				wrapper.current.style.padding = `0 ${Math.max(60, (width - 1160) / 2) - 20}px`
			} else {
				wrapper.current.style.padding = ''
			}

			const itemWidth = $(wrapper.current).find('li').get(0).clientWidth
			const current = parseInt(scroller.current.scrollLeft / itemWidth)
			scroller.current.scrollLeft = itemWidth * current
		}

		function scroll() {
			const x = scroller.current.scrollLeft
			setPrevHidden(items.length < 2 || x <= 0)
			setNextHidden(items.length < 2 || x >= wrapper.current.clientWidth - scroller.current.clientWidth)
		}

		resize()
		scroll()

		window.addEventListener('resize', resize)
		scroller.current.addEventListener('scroll', scroll)

		return () => {
			window.removeEventListener('resize', resize)
		}
	}, [items])

	const scrollTo = useCallback((index) => {
		const itemWidth = $(wrapper.current).find('li').get(0).clientWidth
		const x = Math.min(index * itemWidth, wrapper.current.clientWidth - scroller.current.clientWidth)
		$(scroller.current).stop().animate({ scrollLeft: x }, 600, 'ioX4')
	}, [])

	const prev = useCallback(() => {
		const itemWidth = $(wrapper.current).find('li').get(0).clientWidth
		const index = Math.ceil(scroller.current.scrollLeft / itemWidth) - 1
		scrollTo(index)
	}, [scrollTo])

	const next = useCallback(() => {
		const itemWidth = $(wrapper.current).find('li').get(0).clientWidth
		const index = Math.floor(scroller.current.scrollLeft / itemWidth) + 1
		scrollTo(index)
	}, [scrollTo])

	return (
		<div className={styles.slider}>
			<div className={styles.scroller} ref={scroller}>
				<div className={styles.placer}>
					<ul ref={wrapper} style={{ width: items.length * 100 + '%' }}>
						{items.map((item, i) => (
							<li key={i} style={{ width: 100 / items.length + '%' }}>
								<div className={styles.image}>
									<Image src={item.image} minWidth={0} />
								</div>
								<div
									className={styles.caption}
									dangerouslySetInnerHTML={{ __html: item.caption }}
								></div>
							</li>
						))}
					</ul>
				</div>
			</div>
			<div className={styles.arrows}>
				<div></div>

				{}
				<button className={styles.prev} onClick={() => prev()} aria-hidden={prevHidden}>
					prev
				</button>
				<button className={styles.next} onClick={() => next()} aria-hidden={nextHidden}>
					next
				</button>
			</div>
		</div>
	)
}

export default Slider
