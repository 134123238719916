import React from 'react'

import SEO from 'src/components/seo/index'
import Header from 'src/components/header/setting'
import Mainview from 'src/components/mainview/index'
import Title from 'src/components/title/index'
import Inner from 'src/components/inner/index'
import Image from 'src/components/image/index'
import Prices from 'src/components/prices/body_styling'
import Facility from 'src/components/facility/index'
import Link from 'src/components/link/index'
import CampaignButton from 'src/components/campaign/button'
import ReadyToGo from 'src/components/readytogo/index'

import styles from './index.module.scss'

const BodystylingPage = () => {
	const send = (name) => {
		if (`gtag` in window) {
			window.gtag(`event`, `click`, {
				event_category: `formlink`,
				event_label: name,
				value: 1,
			})
		}
	}

	return (
		<div className={styles.bodystyling}>
			<SEO title="Body Styling" />
			<Header hasBack={true} />

			<Mainview className={styles.mainview}>
				<div className={styles.mainimage}>
					<Inner className={styles.title}>
						<h1>
							<img src="/img/bodystyling/logo.svg" alt="Body Styling" />
						</h1>
					</Inner>
				</div>
			</Mainview>

			<div id="about" className={styles.about}>
				<Inner>
					<div className={styles.aboutText}>
						<p>
							エステとフィットネスを同時に行うと
							<wbr />
							自分史上最速で綺麗になることができる。
							<br />
							あなたの綺麗になりたいを自分の手で実現。
							<br />
							忙しい女性達にぴったりな
							<wbr />
							全く新しいスタイルです。
						</p>
					</div>
					<div className={styles.aboutButtons}>
						<Link
							to="https://entry.thefit.jp/join/000102/"
							onClick={() => send(`ekoda`)}
							className={styles.forAdmission}
						>
							入会はこちら
						</Link>
						<CampaignButton className={styles.forCampaign} />
					</div>
				</Inner>
			</div>

			<section id="features" className={styles.features}>
				<ol className={styles.featuresList}>
					<li className={styles.features01}>
						<div className={styles.featuresImage}>
							<div className={styles.placer}>
								<Image src="/bodystyling/features01.jpg" minWidth={0} />
							</div>
						</div>
						<div className={styles.featuresBlock}>
							<div>
								<h3>Features of Body Styling 01</h3>
								<div>
									<strong>定額、使いたい放題と完全個室</strong>
									<p>
										ジム会員様は、￥5,500/月の定額で何度でも使いたい放題！
										<br />
										さらに「完全個室」なので人目を気にすることがありません。
										<br />
										自分の好きな部位を徹底的にエステし放題です。
									</p>
									<p>
										ジム会員様以外でも、月額のセルフエステ会員としてご利用頂けます。
										<br />
										45分 All Day ￥8,778 / 月<br />
										75分 All Day ￥10,978 / 月
									</p>
									<p>※表記はすべて税込での金額になります。</p>
								</div>
							</div>
						</div>
					</li>
					<li className={styles.features02}>
						<div className={styles.featuresImage}>
							<div className={styles.placer}>
								<Image src="/bodystyling/features02.jpg" minWidth={0} />
							</div>
						</div>
						<div className={styles.featuresBlock}>
							<div>
								<h3>Features of Body Styling 02</h3>
								<div>
									<strong>フィットネスとエステティックの相乗効果</strong>
									<p>
										痩身エステをした後は、身体の代謝がアップするので、通常時にトレーニングするよりもより脂肪が燃焼しやすくなるのでダイエット効果が高くなります。トレーニングとエステを併用すれば、痩せにくい部分をエステでアプローチできるため、気になる部分痩せが期待できます。体成分分析措置InBodyで、体重、脂肪量、筋肉量などが何回でも測定出来きます。エステで施術すると1回で2~3万円するところ、プロ仕様のマシンが使い放題！
									</p>
								</div>
							</div>
						</div>
					</li>
					<li className={styles.features03}>
						<div className={styles.featuresImage}>
							<div className={styles.placer}>
								<Image src="/bodystyling/features03.jpg" minWidth={0} />
							</div>
						</div>
						<div className={styles.featuresBlock}>
							<div>
								<h3>Features of Body Styling 03</h3>
								<div>
									<strong>WEBでいつでも簡単予約</strong>
									<p>
										忙しくても、どこにいてもWEB専用ページから簡単予約。
										<br />
										ご来店したら受付をし、セルフエステスタート。
										<br />
										終わりの時間も決まっているので、予定を立てやすいのがポイントです。
										<br />
										ジムに入会して、セルフエステもオプションでご利用されたい方はWEB入会が可能。施設で会員証を発行いたします。
										<br />
										セルフエステのみの会員を希望の方は、施設で会員証を発行する際にセルフエステ専用予約サイトの案内をいたします。
									</p>
									<p>※1日最大1回75分利用可能</p>
								</div>
							</div>
						</div>
					</li>
				</ol>
			</section>

			<section id="facilities" className={styles.facilities}>
				<Title
					h2="FACILITIES"
					sub={
						<div className={styles.facilitiesText}>
							最新のセルフエステマシンをご用意。
							<br />
							また、体重・筋肉量・体脂肪率など、詳細な測定が出来る体組成計「InBody」も設置しております。
							<br />
							各部屋にはタブレット端末があり、より効果的な施術方法を動画を見ながら誰でも簡単に行うことができ、ご自身のペースで無理なく続けられます。
						</div>
					}
				/>
				<Facility
					items={[
						{
							image: `/bodystyling/facility_01.jpg`,
							caption: `<h3>多機能痩身エステマシン</h3>1台で機能を備えたボディーラインやフェイシャルケアも行える最新のマシンです。キャビテーション、ラジオ波、EMS＆吸引の3機能を組み合わせて、アプローチできます。	`,
						},
						{
							image: `/bodystyling/facility_02.jpg`,
							caption: `<h3>コラーゲンマシン</h3>アンチエイジングが期待できる「光のエステ」<br />光が肌の奥の真皮層まで届き、肌を活性化します。繰り返し照射することでシミ・シワが薄くなる効果が期待できます。`,
						},
					]}
				/>
			</section>

			<section id="price" className={styles.price}>
				<div>
					<Title h2="PRICE" />
					<Inner>
						<Prices />
					</Inner>
				</div>
			</section>

			<div className={styles.links}>
				<Inner>
					<div className={styles.linksContent}>
						<Link to="https://bodystyling.hacomono.jp" className={styles.forMypage}>
							マイページ
						</Link>
						<Link to="/agreement/#body-styling" className={styles.forAgreement}>
							規約・禁忌
						</Link>
					</div>
				</Inner>
			</div>

			<ReadyToGo />
		</div>
	)
}

export default BodystylingPage
